import { Component, Field, SugaredRelativeSingleField, useEntity, useField } from '@contember/interface'
import {
  AudioFileDataExtractorProps,
  FileUrlDataExtractorProps,
  GenericFileMetadataExtractorProps,
  ImageFileDataExtractorProps,
  VideoFileDataExtractorProps,
} from '@contember/react-uploader'
import { EditIcon, FileIcon, InfoIcon, TrashIcon } from 'lucide-react'
import * as React from 'react'
import { ComponentType, ReactNode } from 'react'
import { formatBytes, formatDate, formatDuration } from '../formatting'
import { formatImageResizeUrl } from '../images'
import { Button } from '../ui/button'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import { Dialog, DialogContent, DialogTrigger } from '@radix-ui/react-dialog'

export type UploadedImageViewProps = FileUrlDataExtractorProps &
  GenericFileMetadataExtractorProps &
  ImageFileDataExtractorProps & {
    DestroyAction?: ComponentType<{ children: ReactNode }>;
    small?: boolean;
    edit?: ReactNode
  };

export const UploadedImageView = Component<UploadedImageViewProps>(
  ({ DestroyAction, edit, ...props }) => {
    const url = useField<string>(props.urlField).value;
    return (
      <div
        className={`flex items-center justify-center ${props.small ? "h-12 w-12" : "h-40 w-40"
          } rounded-md group relative`}
      >
        {url && (
          <img
            src={formatImageResizeUrl(url)}
            className="max-w-full max-h-full"
          />
        )}
        <FileActions DestroyAction={DestroyAction} edit={edit}>
          <ImageMetadata {...props} />
        </FileActions>
      </div>
    );
  },
  (props) => {
    return (
      <>
        <Field field={props.urlField} />
        <ImageMetadata {...props} />
      </>
    );
  }
);

const ImageMetadata = Component(
  ({ heightField, widthField, ...props }: UploadedImageViewProps) => {
    return (
      <Metadata {...props}>
        <DimensionsMeta widthField={widthField} heightField={heightField} />
      </Metadata>
    );
  }
);

export type UploadedAudioViewProps = FileUrlDataExtractorProps &
  GenericFileMetadataExtractorProps &
  AudioFileDataExtractorProps & {
    DestroyAction?: ComponentType<{ children: ReactNode }>;
  };

export const UploadedAudioView = Component<UploadedAudioViewProps>(
  ({ DestroyAction, ...props }) => {
    const url = useField<string>(props.urlField).value;
    return (
      <div className="flex items-end justify-center h-40 max-w-80 rounded-md group">
        {url && (
          <audio
            src={url}
            controls
            className="max-w-full max-h-full"
            controlsList="nodownload noremoteplayback noplaybackrate"
          />
        )}
        <FileActions DestroyAction={DestroyAction}>
          <AudioMetadata {...props} />
        </FileActions>
      </div>
    );
  },
  (props) => {
    return (
      <>
        <Field field={props.urlField} />
        <AudioMetadata {...props} />
      </>
    );
  }
);

const AudioMetadata = ({ durationField, ...props }: UploadedAudioViewProps) => {
  return (
    <Metadata {...props}>
      <MetaField
        field={durationField}
        label="Duration:"
        format={formatDuration}
      />
    </Metadata>
  );
};

export type UploadedVideoViewProps = FileUrlDataExtractorProps &
  GenericFileMetadataExtractorProps &
  VideoFileDataExtractorProps & {
    DestroyAction?: ComponentType<{ children: ReactNode }>;
  };

export const UploadedVideoView = Component<UploadedVideoViewProps>(
  ({ DestroyAction, ...props }) => {
    const url = useField<string>(props.urlField).value;
    return (
      <div className="flex items-center justify-center h-40 max-w-60 rounded-md group">
        {url && (
          <video
            src={url}
            controls
            className="max-w-full max-h-full"
            controlsList="nodownload noremoteplayback noplaybackrate"
          />
        )}
        <FileActions DestroyAction={DestroyAction}>
          <VideoMetadata {...props} />
        </FileActions>
      </div>
    );
  },
  (props) => {
    return (
      <>
        <Field field={props.urlField} />
        <VideoMetadata {...props} />
      </>
    );
  }
);

const VideoMetadata = Component(
  ({
    durationField,
    widthField,
    heightField,
    ...props
  }: UploadedVideoViewProps) => {
    return (
      <Metadata {...props}>
        <MetaField
          field={durationField}
          label="Duration:"
          format={formatDuration}
        />
        <DimensionsMeta widthField={widthField} heightField={heightField} />
      </Metadata>
    );
  }
);

export type UploadedAnyViewProps = FileUrlDataExtractorProps &
  GenericFileMetadataExtractorProps & {
    DestroyAction?: ComponentType<{ children: ReactNode }>;
  };

export const UploadedAnyView = Component<UploadedAnyViewProps>(
  ({ DestroyAction, ...props }) => {
    const url = useField<string>(props.urlField).value;
    return (
      <div className="flex h-40 w-40 rounded-md group">
        <a
          href={url ?? "#"}
          target="_blank"
          rel="noreferrer"
          className="text-blue-600 hover:text-blue-700 underline overflow-hidden whitespace-nowrap overflow-ellipsis flex flex-col group/anchor flex-1 items-center justify-center"
        >
          <FileIcon className="h-16 w-16 text-gray-400 group-hover/anchor:text-gray-500 transition-all" />
          {props.fileNameField ? (
            <span>
              <Field field={props.fileNameField} />
            </span>
          ) : null}
        </a>
        <FileActions DestroyAction={DestroyAction}>
          <Metadata {...props} />
        </FileActions>
      </div>
    );
  },
  (props) => (<>
    <Field field={props.urlField} />
    <Metadata {...props} />
  </>)
);

type MetadataProps = FileUrlDataExtractorProps &
  GenericFileMetadataExtractorProps & {
    children?: ReactNode;
  };

const Metadata = Component(
  ({
    children,
    urlField,
    fileSizeField,
    fileNameField,
    lastModifiedField,
    fileTypeField,
  }: MetadataProps) => {
    return (
      <div className="grid grid-cols-[6rem_1fr] gap-2">
        <MetaField field={fileSizeField} label="Size:" format={formatBytes} />
        <MetaField field={fileTypeField} label="Type:" />
        <MetaField field={fileNameField} label="File name:" />
        {children}
        <MetaField
          field={lastModifiedField}
          label="Date:"
          format={formatDate}
        />
        <MetaField
          field={urlField}
          label="URL:"
          format={(url) => (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 underline overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              {url.replace(/^(.{15}).*(.{15})$/, "$1…$2")}
            </a>
          )}
        />
      </div>
    );
  }
);

const DimensionsMeta = Component(
  ({
    widthField,
    heightField,
  }: {
    widthField?: SugaredRelativeSingleField["field"];
    heightField?: SugaredRelativeSingleField["field"];
  }) => {
    const entity = useEntity();
    if (!widthField || !heightField) {
      return null;
    }
    const width = entity.getField<number>(widthField).value;
    const height = entity.getField<number>(heightField).value;

    return (
      <>
        <span className="font-semibold text-right">Dimensions:</span>
        <span>
          {width} x {height} px
        </span>
      </>
    );
  },
  ({ widthField, heightField }) => {
    return (
      <>
        {widthField && <Field field={widthField} />}
        {heightField && <Field field={heightField} />}
      </>
    );
  }
);

interface MetaFieldProps {
  field?: SugaredRelativeSingleField["field"];
  label: ReactNode;
  format?: (value: any) => ReactNode;
}
const MetaField = Component<MetaFieldProps>(
  ({ field, label, format = (it) => it }) => {
    const entity = useEntity();
    return field ? (
      <>
        <span className="font-semibold text-right">{label}</span>
        <span>{format(entity.getField(field).value)}</span>
      </>
    ) : null;
  },
  ({ field }) => {
    return field ? <Field field={field} /> : null;
  }
);

const FileActions = ({ DestroyAction, children, edit, editType }: {
  children: ReactNode
  DestroyAction?: ComponentType<{ children: ReactNode }>
  edit?: ReactNode
  editType?: 'popover' | 'dialog'
}) => {
  return (
    <div className="absolute -top-2 -right-1 p-0.5 bg-gray-200 border border-gray-300 rounded shadow flex gap-1 opacity-0 transition-opacity group-hover:opacity-100">
      <FileActionPopover buttonChildren={<InfoIcon className="h-3 w-3" />}>
        <div className="text-sm">
          {children}
        </div>
      </FileActionPopover>
      {edit && (
        <FileActionPopover buttonChildren={<EditIcon className="h-3 w-3" />} type={editType}>
          {edit}
        </FileActionPopover>)
      }
      {DestroyAction && <DestroyAction>
        <Button variant={'ghost'} size={'sm'} className={'p-0.5 h-5 w-5 text-red-500'}>
          <TrashIcon className="h-3 w-3" />
        </Button>
      </DestroyAction>}
    </div>
  )
}

const FileActionPopover = ({ children, buttonChildren, type = 'popover' }: { children: ReactNode; buttonChildren: ReactNode; type?: 'popover' | 'dialog' }) => {
  if (type === 'popover') {
    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button variant={'ghost'} size={'sm'} className={'p-0.5 h-5 w-5'} onClick={e => e.stopPropagation()}>
            {buttonChildren}
          </Button>
        </PopoverTrigger>
        <PopoverContent onClick={e => e.stopPropagation()}>
          {children}
        </PopoverContent>
      </Popover>
    )
  }
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={'ghost'} size={'sm'} className={'p-0.5 h-5 w-5'} onClick={e => e.stopPropagation()}>
          {buttonChildren}
        </Button>
      </DialogTrigger>
      <DialogContent className={'max-w-[90vw] max-h-[90vh] overflow-auto'}>

        {children}
      </DialogContent>
    </Dialog>
  )
}
